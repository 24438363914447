
export default function About() {
    return (
        <div className="about">
            <h2>Who we are?</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus debitis eos quo ex, illo odio et vitae deserunt tempora officiis inventore sunt quisquam iste nulla, numquam eum! Quibusdam, ipsam cumque?</p>
            <h2>Why choose us?</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. In eligendi voluptate ut sequi, quasi facere laudantium tempora accusantium totam reiciendis repudiandae iure adipisci amet ipsam voluptates dolorem nisi corporis. Culpa?</p>
        </div>
    )
}